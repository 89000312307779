import { useState, useEffect } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Layout, Row, Col, Spin, Typography } from 'antd'
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import axios from "axios"
import { URL, ALLOWED_PROJECTS, LOADING, TIMEOUT, WALLPAPER, MENUPATH_ROADAUDIT } from "./systemparameter"
import MainFooter from './footer'
import { formLayout } from "./layout"
import { clearUserSession, createUserSession, storeBackgroundColor, storeBorderColor, storeSystemParameter } from './usersession'
import { loadSystemParameters } from './loadsystemparameters'
import { reportError } from './utility'
import { useCookies } from "react-cookie"
import { DARKTHEMEBACKGROUNDCOLOR, DARKTHEMEBORDERCOLOR, LIGHTTHEMEBACKGROUNDCOLOR, LIGHTTHEMEBORDERCOLOR, MENUPATH_PROJECT } from '../common/systemparameter'

const { Content, Footer } = Layout
const DEFAULTBREADCRUMB = "IAS (Internal Audit System) 2023"
const { Title } = Typography


const Login = () => {
    const navigate = useNavigate()
    const [cookies, setCookies] = useCookies(['useDarkTheme'])
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    // Set theme colors.
    if(cookies['useDarkTheme'] == '0' || cookies['useDarkTheme'] == undefined) {
        storeBackgroundColor(LIGHTTHEMEBACKGROUNDCOLOR)
        storeBorderColor(LIGHTTHEMEBORDERCOLOR)
    }
    else {
        storeBackgroundColor(DARKTHEMEBACKGROUNDCOLOR)
        storeBorderColor(DARKTHEMEBORDERCOLOR)
    }
    
    //--------------
    // To login
    //--------------
    const onSubmit = () => {
        setLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${URL}rest-auth/login/`, {
                username: values.username,
                password: values.password
            }, {
                timeout: parseInt(TIMEOUT) * 6
            })
            .then( response => {
                const defaultProject = response.data.allowed_projects.filter(project => project.default == true)
                
                storeSystemParameter(ALLOWED_PROJECTS, response.data.allowed_projects.map(project => project.project))
                                
                createUserSession(
                    response.data.allowed_projects[0].user_id,
                    values.username, 
                    response.data.user_groups.map( group => group.name), 
                    defaultProject[0].project_data.id,
                    defaultProject[0].project_data.name,
                    response.data.key)

                // Load system parameters.
                loadSystemParameters()
                .then(() => {
                    navigate(MENUPATH_ROADAUDIT)
                })
                .catch(error => {
                    clearUserSession()
                    message.error("System anomaly detected.")
                })
            })
            .catch( error => {
                if(String(error).includes("timeout")) message.error("Login timeout.")
                reportError(error)
            })
            .finally(() => {
                setLoading(false)
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    useEffect(() => {
        clearUserSession()    
    }, [])
    
    return(
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Layout>
            <Content style={{
                backgroundImage: `url(${WALLPAPER})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'}}>

                <Row justify='center' style={{height: "calc(100vh - 58px)", alignContent: "center"}}>
                    <Col xs={24} xl={8}>
                        <Row justify="center">
                            <Col>
                                <Title style={{ color: '#005288', fontWeight: "bold" }}>INTERNAL AUDIT SYSTEM</Title>
                            </Col>
                        </Row>
                    
                        <Form form={form} onFinish={onSubmit} {...formLayout}>
                            <Form.Item name="username" label=""
                                rules={[
                                    { required: true, message: "Username is required."},
                                ]}>
                                <Input prefix={<UserOutlined type="user" />} placeholder="Username"/>
                            </Form.Item>

                            <Form.Item name="password" label="" 
                                rules={[
                                    { required: true, message: "Password is required."},
                                ]}>
                                <Input.Password prefix={<LockOutlined type="lock" />} placeholder="Password"/>
                            </Form.Item>
                        
                            <Row justify="center">
                                <Col span={8}></Col>
                                <Col span={8} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="submit" loading={loading} style={{ borderColor: "white" }}>Login</Button>
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
            <Footer>
                <MainFooter breadCrumb={DEFAULTBREADCRUMB}/>
            </Footer>
        </Layout>
        </Spin>
    )
}

export default Login