import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Descriptions, Spin, Upload, DatePicker, Input, Table, Image } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, QuestionCircleOutlined, UploadOutlined, DeleteOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATETIMEFORMAT, LOADING, SEARCHOPTIONVIEWWIDTH, MAXPHOTOS, MENUPATH_ROADAUDIT, FALLBACK, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { createImageFile, getBase64, reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor, storeRoadId, storeRoadIdId, SYSPARAM } from "../common/usersession"
import dayjs from 'dayjs'
import { walltiles } from '../common/layout'
import RoadSelect from '../common/roadselect'
import { EditableCell, EditableRow } from '../common/editabletablecell'
import uuid from 'react-native-uuid'
import RecordTypeSelect from '../common/recordtypesselect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { TextArea } = Input


const RoadAuditUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    //const audit = JSON.parse(urlParams.get('audit'))
    
    const [roadIdId, setRoadIdId] = useState(urlParams.get("roadIdId"))
    const [roadId, setRoadId] = useState(urlParams.get("roadNameAndId"))
    const [auditedOn, setAuditedOn] = useState(dayjs(urlParams.get("auditedOnUnformatted")), UNIDATETIMEFORMAT)
    const photoFile = useRef([])
    const [photoDataSource, setPhotoDataSource] = useState([])
    const [recordTypeId, setRecordTypeId] = useState(urlParams.get("recordTypeId"))
    const [recordType, setRecordType] = useState(urlParams.get("recordType"))
            
    const updateRoadAudit = () => {
        storeRoadIdId(roadIdId)
        storeRoadId(roadId)
                
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}roadaudit/update/${urlParams.get("id")}/`, {
                road: roadIdId,
                audited_on: auditedOn,
                chainage_from: values.chainageFrom,
                chainage_to: values.chainageTo,
                gps_location: values.gpsLocation != "" ? values.gpsLocation : '',
                record_type: recordTypeId,
                findings: recordTypeId != SYSPARAM("Conclusion") ? values.findings : '',
                conclusion: recordTypeId == SYSPARAM("Conclusion") ? values.conclusion : '',
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                setIsLoading(true)
                let count = 0
                
                for(const file of photoFile.current) {
                    let data = new FormData()
                    data.append("img", file)
                    const img = photoDataSource.filter( photo => photo.key == file.uid)
                    data.append("remark", img[0].remark)

                    // Sanitize chainage value.
                    let chainageFrom = img[0].chainageFrom
                    if(String(chainageFrom).trim() == '') chainageFrom = ''
                    let chainageTo = img[0].chainageTo
                    if(String(chainageTo).trim() == '') chainageTo = ''
                    
                    data.append("chainage_from", chainageFrom)
                    data.append("chainage_to", chainageTo)
                    data.append("road_audit", urlParams.get("id"))
        
                    fetch(`${APIURL}roadaudit/uploadphoto/${urlParams.get("id")}/`, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Token ${getUserAuthToken()}`,
                        },
                        body: data,
                    })
                    .then( response => {
                        count++
                        if(count == photoFile.current.length) {
                            setIsLoading(false)
                            message.info("Road audit record updated.")
                            //navigate(MENUPATH_ROADAUDIT)

                            // Remove obsolete photos.
                            axios.patch(`${APIURL}roadaudit/removeobsoletephoto/${urlParams.get("id")}/`, {
                            }, { 
                                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
                            })
                        }
                    })
                    .catch( error => {
                        setIsLoading(false)
                        reportError(error, `Failed to upload photo.`)
                    })
                    .finally(() => {
                        refreshUserSession()
                    })
                }
            })
            .catch( error => {
                reportError(error, `Failed to update road audit record.`)
            })
            .finally(() => {
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}roadaudit/delete/${urlParams.get("id")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Road audit record deleted.`)
            navigate(MENUPATH_ROADAUDIT)
        })
        .catch( error => {
            reportError(error, "Failed to delete road audit record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getRoadAuditRemark = (id) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}roadauditremark/${urlParams.get("id")}/`, {
            params: { },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            form.setFieldsValue({
                findings: urlParams.get("recordType") != "Conclusion" ? response.data.findings : '',
                conclusion: urlParams.get("recordType") == "Conclusion" ? response.data.conclusion : '',
            })
        })
        .catch( error => {
            reportError(error, "Failed to get road audit remark.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getRoadAuditPhoto = (roadAuditId) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}roadauditphoto/`, {
            params: { 
                road_audit: roadAuditId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( async response => {
            const buildPhotos = () => {
                const photos = []
                const filenames = []
                const files = []

                return new Promise((resolve) => {
                    for(const photo of response.data.results) {
                        if(filenames.includes(photo.img)) continue
                        filenames.push(photo.img)
                        
                        const uid = uuid.v4()

                        createImageFile(photo.img)
                        .then( file => {
                            file["uid"] = uid
                            files.push(file)
                        
                            let chainageFrom = photo.chainage_from
                            if(chainageFrom == null) chainageFrom = ''
                            let chainageTo = photo.chainage_to
                            if(chainageTo == null) chainageTo = ''

                            photos.push({
                                key: uid,
                                img: `${photo.img}?now=${dayjs().valueOf()}`,
                                name: file.name,
                                chainageFrom: chainageFrom,
                                chainageTo: chainageTo,
                                remark: photo.remark
                            })

                            if(photos.length == response.data.results.length) {
                                photoFile.current = files   
                                return resolve(photos)
                            }
                        })
                    }
                })
            }

            setIsLoading(true)
            buildPhotos()
            .then( photos => {
                photos.sort((a, b) => a.chainageFrom - b.chainageFrom)
                const numberedPhotos = []
                let idx = 0
                for(const photo of photos) {
                    idx++
                    photo["idx"] = `${idx}.`
                    numberedPhotos.push(photo)
                }

                setPhotoDataSource(numberedPhotos)
                setIsLoading(false)
            })
        })
        .catch( error => {
            reportError(error, "Failed to get road audit photo.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const removePhoto = (e, record) => {
        photoFile.current = photoFile.current.filter(photo => photo.uid != record.key)
        
        const numberedPhotos = [] 
        let idx = 1
        const filteredPhotoDataSource = photoDataSource.filter(photo => photo.key != record.key)
        for(const photo of filteredPhotoDataSource) {
            photo["idx"] = `${idx}.`
            numberedPhotos.push(photo)
            idx++
        }
        setPhotoDataSource(numberedPhotos)
    }

    const onUpdate = async (e) => {
        if(photoDataSource.length == 0) {
            message.warning('Photo is required.')
            return
        }

        if(photoDataSource.length > MAXPHOTOS) {
            message.warning(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
            return
        }

        for(const photo of photoDataSource) {
            if(String(photo.chainageFrom).trim() == '' && String(photo.chainageTo).trim() != '') {
                message.warning('Missing Chainage (From) detected.')
                return
            }

            if(photo.chainageFrom != undefined && photo.chainageFrom != "" && isNaN(parseInt(photo.chainageFrom))) {
                message.warning('Invalid Chainage (From) detected.')
                return
            }

            if(photo.chainageTo != undefined && photo.chainageTo != "" && isNaN(parseInt(photo.chainageTo))) {
                message.warning('Invalid Chainage (To) detected.')
                return
            }
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update road audit record confirmed?</p></Space>,
            onOk() { updateRoadAudit() },
            onCancel() {},
            centered: true
        })
    }

    const onAuditedOnChange = (datetime) => {
        setAuditedOn(datetime)
    }

    const onRoadIdChange = (e, value) => {
        if(e != undefined) {
            setRoadIdId(e)
            setRoadId(value.children)
        }
        else {
            setRoadIdId(0)
            setRoadId('')
        }
    }

    const onRecordTypeChange = (e, value) => {
        if(e != undefined) {
            setRecordTypeId(e)
            setRecordType(value.children)
        }
        else {
            setRecordTypeId(0)
            setRecordType('')
        }
    }

    const validateAuditedOn = (() => {
        if(auditedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Audited on is required."))
    })

    const validateRoadId = (() => {
        if(roadId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Road ID is required."))
    })

    const onBack = () => {
        navigate(MENUPATH_ROADAUDIT)
    }

    const props = {
        showUploadList: false,
        /* onRemove: file => {
             setPhotoFile(photoFile.filter(photo => photo.name != file.name))
        },*/
        beforeUpload: async (file, fileList) => {
            if(fileList.length > 10) {
                message.warning(`Please do not select more than 10 photos.`)
                return false
            }

            if(photoDataSource.length + fileList.length > MAXPHOTOS) {
                message.warning(`Number of photos exceeding ${MAXPHOTOS}.`)
                return false
            }

            const populatePhotoDataSource = () => {
                fileList.forEach( file => {
                    let photoUIDs = photoFile.current.map( file => file.uid )
                    if(!photoUIDs.includes(file.uid))
                        photoFile.current = photoFile.current.concat(file)
                })

                let count = 0
                let imgDataSource = []

                for(const file of fileList) {
                    const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
                    if(!isImage) {
                        message.error(`${file.name} is not an image file.`)
                        return false
                    }

                    let idx = photoDataSource.length
                    
                    getBase64(file)
                    .then( b64 => {
                        count++

                        imgDataSource.push({
                            key: file.uid,
                            filename: file.name,
                            img: b64,
                            chainageFrom: '',
                            chainageTo: '',
                            remark: '',
                            idx: `${idx + count}.`
                        })
                        
                        if(count == fileList.length) setPhotoDataSource(photoDataSource.concat(imgDataSource))
                    })
                }
            }

            populatePhotoDataSource()

            return false
        },
        multiple: true,
        maxCount: {MAXPHOTOS},
        fileList: photoFile.current,
    }

    useEffect(() => {
        form.setFieldsValue({
            gpsLocation: urlParams.get("gpsLocation"),
            chainageFrom: urlParams.get("chainageFrom"),
            chainageTo: urlParams.get("chainageTo"),
        })

        getRoadAuditRemark(urlParams.get("id"))
        getRoadAuditPhoto(urlParams.get("id"))
    }, [])

    const photoColumns = [
        { title: 'No.', dataIndex: 'idx', key: 'idx', align: 'center' },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Image width={200} fallback={FALLBACK} src={img} onClick={(e) => e.stopPropagation() }/>
                    </>
                else
                    return null
            }
        },
        {title: 'Chainage (From)', dataIndex: 'chainageFrom', key: 'chainageFrom', width: '10vw', align: 'center', editable: true },
        {title: 'Chainage (To)', dataIndex: 'chainageTo', key: 'chainageTo', width: '10vw', align: 'center', editable: true },
        {title: 'Remark', dataIndex: 'remark', key: 'remark', width: '50vw', align: 'left', editable: true },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Popconfirm title="Delete photo confirmed?" 
                    onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => removePhoto(e, record)} 
                    okText="Yes" cancelText="No">
                    <Button danger type="primary" icon={<DeleteOutlined/>} />
                </Popconfirm>
            }
        }
    ]

    const handleSave = (row) => {
        const newData = [...photoDataSource]
        const index = newData.findIndex((item) => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })

        setPhotoDataSource(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }

    const columns = photoColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        }
    })

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Road Audit">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update road audit record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onUpdate} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="roadId" label="Road ID"
                        rules={[
                            { required: true, validator: validateRoadId },
                        ]}>
                        <RoadSelect onChange={onRoadIdChange} defaultValue={roadId} allowClear={false} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="gpsLocation" label="GPS Location">
                        <Input placeholder="GPS Location" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="chainageFrom" label="Chainage (From)">
                        <Input placeholder="Chainage (From)" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="chainageTo" label="Chainage (To)">
                        <Input placeholder="Chainage (To)" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="auditedOn" label="Audited On"
                        rules={[
                            { required: true, validator: validateAuditedOn },
                        ]}>
                        <DatePicker showTime onChange={onAuditedOnChange} defaultValue={auditedOn} format={DATETIMEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="photoFile" label={`Photo (max. ${MAXPHOTOS})`}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />}>Add Photo</Button>
                        </Upload>
                    </Form.Item> 

                    <Form.Item name="recordType" label="Record Type">
                        <RecordTypeSelect withBlank={false} marginLeft={10} onChange={onRecordTypeChange} defaultValue={recordType}/>
                    </Form.Item>

                    { recordTypeId != SYSPARAM("Conclusion") &&
                        <Form.Item name="findings" label="Onsite Findings">
                            <TextArea rows={5} placeholder="Onsite Findings" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                        </Form.Item>
                    }

                    { recordTypeId == SYSPARAM("Conclusion") &&
                        <Form.Item name="conclusion" label="Conclusion">
                            <TextArea rows={5} placeholder="Conclusion" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                        </Form.Item>
                    }

                    <Row><Col><Space><div /></Space></Col></Row>

                    { photoDataSource.length > 0 &&
                        <>
                        <Row justify="center">
                            <Col span={12}>
                                <Table bordered columns={columns} components={components} dataSource={photoDataSource} pagination={false} so/>
                            </Col>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete audit record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onBack} icon={<ArrowLeftOutlined />}>Back</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Road Audit:"
                    subTitle="Update road audit record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default RoadAuditUpdate