import { useState, useEffect } from 'react'
import { Col, Row, Select, Space, Form } from 'antd'

import { getUserAuthToken, getUserProjectId, OTHERSYSPARAM, refreshUserSession, SEARCH_DISTRICT_KEY, SEARCH_DISTRICTID_KEY, SEARCH_ROADID_KEY, SEARCH_ROADIDID_KEY, SEARCH_ZONE_KEY, SEARCH_ZONEID_KEY } from "./usersession"
import axios from 'axios'
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH } from './systemparameter'
import { useMediaQuery } from 'react-responsive'
import { reportError } from './utility'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

const { Option } = Select

// commonSearchForm is needed if horizontal = false
const CommonSearchFormItem = ({onDistrictChange, onZoneChange, onRoadChange, 
    // defaultDistrictId = 0, defaultZoneId = 0, defaultRoadIdId = 0,
    // defaultDistrict = "", defaultZone = "", defaultRoadId = "",
    defaultDistrictId = window.sessionStorage.getItem(SEARCH_DISTRICTID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_DISTRICTID_KEY)) : 0, 
    defaultZoneId = window.sessionStorage.getItem(SEARCH_ZONEID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_ZONEID_KEY)) : 0, 
    defaultRoadIdId = window.sessionStorage.getItem(SEARCH_ROADIDID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_ROADIDID_KEY)) : 0,
    defaultDistrict = window.sessionStorage.getItem(SEARCH_DISTRICT_KEY),
    defaultZone = window.sessionStorage.getItem(SEARCH_ZONE_KEY), 
    defaultRoadId = window.sessionStorage.getItem(SEARCH_ROADID_KEY),
    withBlank = true, horizontal = true, commonSearchForm}) => {
    //const [commonSearchForm] = Form.useForm()

    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    
    const [districtOption, setDistrictOption] = useState([])
    const [zoneOption, setZoneOption] = useState([])
    const [roadOption, setRoadOption] = useState([])
        
    const [districtId, setDistrictId] = useState(defaultDistrictId)
    const [zoneId, setZoneId] = useState(defaultZoneId)
    const [roadIdId, setRoadIdId] = useState(defaultRoadIdId)
                
    const getDistrict = () => {
        axios.get(`${APIURL}district/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( district => <Option key={district.id}>{district.name}</Option>))
            setDistrictOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get district.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const getZone = (districtCode = 0) => {
        axios.get(`${APIURL}zone/`, { 
            params: { 
                district: districtCode,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( zone => <Option key={zone.id}>{zone.name}</Option>))
            setZoneOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get zone.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const getRoad = (districtCode = 0, zone = '') => {
        axios.get(`${APIURL}road/`, { 
            params: { 
                project: getUserProjectId(),
                district: districtCode,
                zone: zone
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( road => <Option key={road.id}>{`${road.road_name} (${road.list} ${road.road_id})`}</Option>))
            setRoadOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get road.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    const districtChange = (e, value) => {
        if(e != null) {
            setDistrictId(e)
            onDistrictChange(e, value)
            window.sessionStorage.setItem(SEARCH_DISTRICTID_KEY, e)
            window.sessionStorage.setItem(SEARCH_DISTRICT_KEY, value.children)
        }
        else {
            onDistrictChange(0, "")
            setDistrictId(0)
            window.sessionStorage.setItem(SEARCH_DISTRICTID_KEY, 0)
            window.sessionStorage.setItem(SEARCH_DISTRICT_KEY, '')
        }

        onZoneChange(0, "")
        onRoadChange(0, "")
                
        setZoneId(0)
        setRoadIdId(0)
                
        getZone(e)
        getRoad(e, '')

        commonSearchForm.setFieldsValue({
            zone: "",
            road: "",
        })
    }

    const zoneChange = (e, value) => {
        if(e != null) {
            setZoneId(e)
            onZoneChange(e, value)
            window.sessionStorage.setItem(SEARCH_ZONEID_KEY, e)
            window.sessionStorage.setItem(SEARCH_ZONE_KEY, value.children)
        }
        else {
            onZoneChange(0, "")
            setZoneId(0)
            window.sessionStorage.setItem(SEARCH_ZONEID_KEY, 0)
            window.sessionStorage.setItem(SEARCH_ZONE_KEY, '')
        }

        onRoadChange(0, "")
        setRoadIdId(0)
        getRoad(districtId, e)

        commonSearchForm.setFieldsValue({
            road: "",
        })
    }

    const roadChange = (e, value) => {
        if(e != null) {
            setRoadIdId(e)
            onRoadChange(e, value)
            window.sessionStorage.setItem(SEARCH_ROADIDID_KEY, e)
            window.sessionStorage.setItem(SEARCH_ROADID_KEY, value.children)
        }
        else {
            onRoadChange(0, "")
            setRoadIdId(0)
            window.sessionStorage.setItem(SEARCH_ROADIDID_KEY, 0)
            window.sessionStorage.setItem(SEARCH_ROADID_KEY, '')
        }
    }

    useEffect(() => {
        getDistrict()
        getZone(defaultDistrictId)
        getRoad(defaultDistrictId, defaultZoneId)

        commonSearchForm.setFieldsValue({
            district: defaultDistrict,
            zone: defaultZone,
            road: defaultRoadId
        })
    }, [])

    return (
        <Form form={commonSearchForm}>
            { horizontal && 
                <Row justify="center">
                    <Col span={1} style={{ textAlign: 'end', marginRight: 10 }}>District :</Col>
                    <Col span={3}>
                        <Form.Item name="district">
                            <Select style={{width: optionWidth}} onChange={districtChange} allowClear showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {districtOption}    
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={1}><Space /></Col>
                    <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Zone :</Col>
                    <Col span={3}>
                        <Form.Item name="zone">
                            <Select style={{width: optionWidth}} onChange={zoneChange} allowClear showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {zoneOption}    
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={1}><Space /></Col>
                    <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Road :</Col>
                    <Col span={3}>
                        <Form.Item name="road">
                            <Select style={{width: optionWidth}} onChange={roadChange} allowClear showSearch optionFilterProp="children"
                                filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {roadOption}    
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            }
            { !horizontal && 
                <>
                {/* <Form form={commonSearchForm} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}> */}
                    <Form.Item name="district" label="District" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                        <Select style={{ width: optionWidth, marginLeft: 10 }} onChange={districtChange} allowClear showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {districtOption}    
                        </Select>
                    </Form.Item>
                    <Form.Item name="zone" label="Zone" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                        <Select style={{ width: optionWidth, marginLeft: 10 }} onChange={zoneChange} allowClear showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {zoneOption}    
                        </Select>
                    </Form.Item>
                    <Form.Item name="road" label="Road ID" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                        <Select style={{ width: optionWidth, marginLeft: 10 }} onChange={roadChange} allowClear showSearch optionFilterProp="children"
                            filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {roadOption}    
                        </Select>
                    </Form.Item>
                {/* </Form> */}
                </>
            }
        </Form>
    )
}

export default CommonSearchFormItem
